// Jquery
window.$ = require( "jquery" );

// React
window.React = require('react');
window.ReactDOM = require('react-dom');

// Redux
window.Redux = require('redux');
window.ReactRedux = require('react-redux');
window.Provider = require('react-redux').Provider;
window.ReactSelect = require('react-select').default;

// Misc
window.Inflector = require('inflected');
window.Immutable = require('immutable');
window.normalizr = require('normalizr');
window.memoize = require('lodash.memoize');
window.request = require('axios').default;
window.createId = require('@paralleldrive/cuid2').createId;
window.lodash = window._ = require('lodash');
window.accounting = require('accounting');
window.Mustache = require('mustache');
window.Twilio = require('twilio-client');
window.templates = {};

// Mixins
window.PureAwesomeMixin = require('client/react/mixins/pure_awesome_mixin');

// I18n
const I18nJS = require('i18n-js').I18n
const translations = require('./translations.json')
I18n = new I18nJS(translations)
I18n.locale = document.documentElement.getAttribute('lang')
window.I18n = I18n

// Date/Time
window.moment = require('moment');

// Make sure axios params are serialized
request.defaults.paramsSerializer = function(params) {
  return require('qs').stringify(params, { arrayFormat: 'brackets' });
};

if(window.envType === 'test') {
  window.timekeeper = require('timekeeper');
}
